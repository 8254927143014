import './App.css';
import NavigationBar from './Components/NavigationBar.js';
import 'tw-elements';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

function App() {
  return (
    <div class="h-screen bg-gray-50">
      <NavigationBar class='sticky'/>
      <div class="p-6 space-y-14 max-w-5xl min-w-min mx-auto ">
        
        <div class="space-y-6">
          
          <h1 class="text-6xl font-bold">Our Mission</h1>
          <p class="text-xl">To create software that can enrich lives by creating an experience that is easy to use and apply to your daily life.</p>
        </div>
        <div class="space-y-6">
          <h1 class="text-6xl font-bold">Our Team</h1>
          <p>We are software developers that are driven by the idea that software should be easy to use and apply every day.</p>
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div class="mx-auto w-full rounded-xl shadow-md p-6 space-y-4 bg-white">
              <div class="flex items-center space-x-4">
                <img class="rounded-full w-32 shadow-md border-2 border-gray-300" alt="Sean" src="https://pbs.twimg.com/profile_images/1142571063110295552/sj71svS7_400x400.jpg" />
                <div>
                  <h2 class="text-2xl font-bold">Sean Robinson</h2>
                  <h3 class="text-md font-bold">Co-Founder</h3>
                </div>
              </div>
            </div>
            <div class="mx-auto w-full rounded-xl shadow-md p-6 space-y-4 bg-white">
              <div class="flex items-center space-x-4">
                <img class="rounded-full w-32 shadow-md border-2 " alt="Camden" src="https://pbs.twimg.com/profile_images/1484575586810953734/sCVCWQGJ_400x400.jpg" />
                <div>
                  <h2 class="text-2xl font-bold">Camden Meyers</h2>
                  <h3 class="text-md font-bold">Co-Founder</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

