import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import 'tw-elements';
import App from "./App";
import BudgetShopper from "./Pages/BudgetShopper";
import InfiniTime from "./Pages/InfiniTime";
import BoardPros from "./Pages/BoardPros"
import Privacy from "./Pages/Privacy";
import Support from "./Pages/Support";
import './index.css';

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="BudgetShopper" element={<BudgetShopper />} />
      <Route path="InfiniTime" element={<InfiniTime />} />
      <Route path="BoardPros" element={<BoardPros />} />
      <Route path="Privacy" element={<Privacy />} />
      <Route path="Support" element={<Support />} />
      <Route
      path="*"
      element={
        <main style={{ padding: "1rem" }}>
          <p>There's nothing here!</p>
        </main>
      }
    />
    </Routes>
  </BrowserRouter>,
  rootElement
);
