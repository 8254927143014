import NavigationBar from '../Components/NavigationBar.js';

export default function Privacy() {
  return (
    <div class="bg-gray-50">
      <NavigationBar class='sticky' />
      <div class="p-6 space-y-14 max-w-5xl min-w-min mx-auto ">
        <article class="prose">
          <h1>
            Privacy Policy
          </h1>
          <h2>1. Introduction</h2>
          <p>
            We respect your privacy and are committed to protecting it
            through our compliance with this policy. All <strong>InfiniCode, LLC</strong> apps are designed to respect user privacy and data security.
          </p>
          <h2>2. Information We Collect</h2>
          <p>
            <strong>InfiniTime</strong> and <strong>BoardPros</strong> do not
            collect any personally identifiable information.
          </p>
          <p>
            <strong>BudgetShopper</strong> collects email addresses for account
            creation. Diagnostics are logged but are not personally identifiable.
          </p>
          <h2>3. How We Use Your Information</h2>
          <p>
            Your information is only collected and used when it is necessary to provide our services.
          </p>
          <h2>4. Changes to Our Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time.
            Thus, you are advised to review this page periodically
            for any changes. These changes are effective immediately
            after they are posted on this page.
          </p>
          <h2>5. Contact Us</h2>
          <p>
            If you have any questions or suggestions about our Privacy
            Policy, do not hesitate to contact us at <a href="mailto:support@infinicode.net">support@infinicode.net</a>.
          </p>
          <h2>6. Consent</h2>
          <p>
            By using our apps, you hereby consent to our Privacy
            Policy and agree to its terms.
          </p>
          <p class="italic">
            Last updated: February 28, 2024
          </p>
        </article>
      </div>
    </div>
  );
}