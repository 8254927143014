import NavigationBar from '../Components/NavigationBar.js';

export default function Support() {
    return (
        <div class="h-screen bg-gray-50">
            <NavigationBar class='sticky' />
            <div class="p-6 space-y-14 max-w-5xl min-w-min mx-auto ">
                <div class="space-y-6">
                    <article class="prose">
                        <h1>Support</h1>
                        Need Help? Contact us at <a href="mailto:support@infinicode.net">support@infinicode.net</a>
                    </article>
                </div>
            </div>
        </div>
    );
}